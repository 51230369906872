<template>
  <div class="container">
    <div class="header">
      <el-row>
        <el-col :span="20">
          <div style="text-align: left;padding: 10px">
            <h1>浙江永琪智能科技有限公司</h1>
            <p>

              浙江永琪智能科技有限公司，位于美丽的金华，是一家致力于智能科技产品研发与推广的公司。我们的使命是用科技改变生活，让未来触手可及。汪汪博士自动冰淇淋销售设备，正是我们这一理念的完美体现。
              <br/>
              <br/>
              汪汪博士自动冰淇淋售卖机不仅仅是一个自动冰淇淋销售设备，它更是永琪科技对未来生活方式的设想和追求。我们希望通过这一设备，为消费者提供便捷、新鲜、美味的冰淇淋体验，同时也为合作伙伴带来可观的经济效益。
              <br/>
              <br/>
              汪汪博士不仅是永琪科技的IP元素，更是我们追求创新、品质与服务的象征。作为一位充满智慧与热情的科学家，汪汪博士带领我们不断探索智能科技的边界，努力将最先进的技术应用于自动冰淇淋销售设备中。
              <br/>
              <br/>
              通过汪汪博士自动冰淇淋销售设备，我们希望为消费者带来新鲜、美味的冰淇淋享受，同时也为合作伙伴创造丰厚的商业价值。这一设备集成了人工智能、物联网、大数据等先进技术，可实现自动化操作、精准控制和高效管理，为顾客带来便捷、个性化的购买体验。
              <br/>
              <br/>
              我们的愿景是成为智能终端销售设备领域的领导者，引领行业创新，为社会创造更多价值。永琪科技拥有一支充满激情和创造力的团队，我们不断追求卓越，努力将最前沿的科技应用于实际生活中。<br/>
              <br/>
              <br/>
              作为一家具有社会责任感的企业，我们始终坚持可持续发展，积极参与公益事业。我们相信，科技的发展应该造福全人类，让生活更加美好。<br/>
              <br/>
              <br/>
              永琪科技诚挚邀请您共同探索智能科技的无限可能，携手共创美好未来！
            </p>
          </div>
        </el-col>
        <el-col :span="3">
          <el-affix :offset="0">
            <el-row>
              <el-col :span="1">
                <div style="margin-top: 48px;text-align: right;">
                  <el-image src="https://dr-wangwang-3d-print.oss-cn-hangzhou.aliyuncs.com/home/assets/phone.png" style="width: 30px;height: 30px"></el-image>
                </div>
              </el-col>
              <el-col :span="23">
                <div style="text-align: right;margin-top: 45px">
                  <h1>400-800-4580</h1>
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="3">
                <div style="margin-top: 25px;text-align: right;">
                  <el-image src="https://dr-wangwang-3d-print.oss-cn-hangzhou.aliyuncs.com/home/assets/icon/email.svg" style="width: 30px;height: 30px"></el-image>
                </div>
              </el-col>
              <el-col :span="21">
                <div style="text-align: right;margin-top: 25px;font-size: 12px">
                  <h1>niwei@cnyongqi.com</h1>
                </div>
              </el-col>
            </el-row>
          </el-affix>
        </el-col>
      </el-row>
    </div>
    <div>
      <img v-for="(item,index) in imgList" :src="item" :key="index">
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data() {
    return {
      imgList: [
          "https://dr-wangwang-3d-print.oss-cn-hangzhou.aliyuncs.com/home/assets/1.png",
          "https://dr-wangwang-3d-print.oss-cn-hangzhou.aliyuncs.com/home/assets/2.png",
          "https://dr-wangwang-3d-print.oss-cn-hangzhou.aliyuncs.com/home/assets/3.png",
          "https://dr-wangwang-3d-print.oss-cn-hangzhou.aliyuncs.com/home/assets/4.png",
          "https://dr-wangwang-3d-print.oss-cn-hangzhou.aliyuncs.com/home/assets/5.png",
          "https://dr-wangwang-3d-print.oss-cn-hangzhou.aliyuncs.com/home/assets/6.png",
          "https://dr-wangwang-3d-print.oss-cn-hangzhou.aliyuncs.com/home/assets/7.png",
          "https://dr-wangwang-3d-print.oss-cn-hangzhou.aliyuncs.com/home/assets/8.png",
          "https://dr-wangwang-3d-print.oss-cn-hangzhou.aliyuncs.com/home/assets/9.png",
          "https://dr-wangwang-3d-print.oss-cn-hangzhou.aliyuncs.com/home/assets/10.png",
          "https://dr-wangwang-3d-print.oss-cn-hangzhou.aliyuncs.com/home/assets/11.png",
          "https://dr-wangwang-3d-print.oss-cn-hangzhou.aliyuncs.com/home/assets/12.png",
          "https://dr-wangwang-3d-print.oss-cn-hangzhou.aliyuncs.com/home/assets/13.png",
          "https://dr-wangwang-3d-print.oss-cn-hangzhou.aliyuncs.com/home/assets/14.png",
          "https://dr-wangwang-3d-print.oss-cn-hangzhou.aliyuncs.com/home/assets/15.png",
          "https://dr-wangwang-3d-print.oss-cn-hangzhou.aliyuncs.com/home/assets/16.png",
          "https://dr-wangwang-3d-print.oss-cn-hangzhou.aliyuncs.com/home/assets/17.png",
          "https://dr-wangwang-3d-print.oss-cn-hangzhou.aliyuncs.com/home/assets/18.png",
          "https://dr-wangwang-3d-print.oss-cn-hangzhou.aliyuncs.com/home/assets/19.png",
          "https://dr-wangwang-3d-print.oss-cn-hangzhou.aliyuncs.com/home/assets/20.png",
          "https://dr-wangwang-3d-print.oss-cn-hangzhou.aliyuncs.com/home/assets/21.png",
          "https://dr-wangwang-3d-print.oss-cn-hangzhou.aliyuncs.com/home/assets/22.png",
          "https://dr-wangwang-3d-print.oss-cn-hangzhou.aliyuncs.com/home/assets/23.png",
          "https://dr-wangwang-3d-print.oss-cn-hangzhou.aliyuncs.com/home/assets/24.png",
          "https://dr-wangwang-3d-print.oss-cn-hangzhou.aliyuncs.com/home/assets/25.png",
          "https://dr-wangwang-3d-print.oss-cn-hangzhou.aliyuncs.com/home/assets/26.png",
      ]
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

.container {
  padding: 5px;
}
.header{
  padding: 0;
  margin: 0;
}
</style>
