<template>
  <div class="container">
    <div class="header">
      <el-row>
        <el-col :span="19">
          <div style="text-align: left;padding: 10px">
            <h1>CHINA ZHEJIANG YONGQI INTELLIGENT TECHNOLOGY CO LTD</h1>
            <p>
              Zhejiang Yongqi Intelligent Technology Co., Ltd. is a technology innovation enterprise that integrates research and development, production, and sales. The company adheres to the concept of "intelligent technology, changing life" and focuses on the research and promotion of intelligent automatic sales equipment.

              As a pioneer in the industry, Yongqi Intelligent Technology has independently developed a series of products with market competitiveness, among which ice cream vending machines and marshmallow vending machines are the company's star products.

              The ice cream vending machine adopts advanced refrigeration technology to achieve rapid production and preservation of ice cream. Users only need to choose their favorite flavor on the machine to enjoy fresh and delicious ice cream. The launch of this product not only brings convenience to consumers, but also provides new business models and profit growth points for businesses.

              Cotton candy vending machines are creative products aimed at young people. Through automated production by machines, users can quickly obtain colorful and uniquely flavored marshmallows. This product not only meets the fresh and unique consumption needs of young people, but also provides new business opportunities for businesses.

              Yongqi Intelligent Technology always adheres to market demand orientation, constantly carrying out technological innovation and product upgrades. We believe that with excellent products and professional services, Yongqi Intelligent Technology will become a leading enterprise in the field of intelligent automatic sales equipment.
            </p>
          </div>
        </el-col>
        <el-col :span="5">
          <el-affix :offset="0">
            <el-row>
              <el-col :span="2">
                <div style="margin-top: 55px;text-align: right;">
                  <el-image src="https://dr-wangwang-3d-print.oss-cn-hangzhou.aliyuncs.com/home/assets/phone.png" style="width: 30px;height: 30px"></el-image>
                </div>
              </el-col>
              <el-col :span="20">
                <div style="text-align: left;margin-top: 45px">
                  <h1>（+86）13505794212</h1>
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="2">
                <div style="margin-top: 25px;text-align: right;">
                  <el-image src="https://dr-wangwang-3d-print.oss-cn-hangzhou.aliyuncs.com/home/assets/icon/email.svg" style="width: 30px;height: 30px"></el-image>
                </div>
              </el-col>
              <el-col :span="22">
                <div style="text-align: left;margin-top: 25px;font-size: 12px">
                  <h1>angel@cnyongqi.com</h1>
                </div>
              </el-col>
            </el-row>
          </el-affix>
        </el-col>
      </el-row>
    </div>
    <div>
      <div style="width: 100%" v-for="(item,index) in imgList" :key="index" >
        <el-image  :src="item" fit="cover" style="width: 50%;height: 100%" lazy></el-image>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data() {
    return {
      imgList: [
        "https://dr-wangwang-3d-print.oss-cn-hangzhou.aliyuncs.com/home/assets/english/1.jpg",
        "https://dr-wangwang-3d-print.oss-cn-hangzhou.aliyuncs.com/home/assets/english/2.jpg",
        "https://dr-wangwang-3d-print.oss-cn-hangzhou.aliyuncs.com/home/assets/english/3.jpg",
        "https://dr-wangwang-3d-print.oss-cn-hangzhou.aliyuncs.com/home/assets/english/4.jpg",
        "https://dr-wangwang-3d-print.oss-cn-hangzhou.aliyuncs.com/home/assets/english/5.jpg",
        "https://dr-wangwang-3d-print.oss-cn-hangzhou.aliyuncs.com/home/assets/english/6.jpg",
        "https://dr-wangwang-3d-print.oss-cn-hangzhou.aliyuncs.com/home/assets/english/7.jpg",
        "https://dr-wangwang-3d-print.oss-cn-hangzhou.aliyuncs.com/home/assets/english/8.jpg",
        "https://dr-wangwang-3d-print.oss-cn-hangzhou.aliyuncs.com/home/assets/english/9.jpg",
        "https://dr-wangwang-3d-print.oss-cn-hangzhou.aliyuncs.com/home/assets/english/10.jpg",
        "https://dr-wangwang-3d-print.oss-cn-hangzhou.aliyuncs.com/home/assets/english/11.jpg",
        "https://dr-wangwang-3d-print.oss-cn-hangzhou.aliyuncs.com/home/assets/english/12.jpg",
        "https://dr-wangwang-3d-print.oss-cn-hangzhou.aliyuncs.com/home/assets/english/13.jpg",
        "https://dr-wangwang-3d-print.oss-cn-hangzhou.aliyuncs.com/home/assets/english/14.jpg",
        "https://dr-wangwang-3d-print.oss-cn-hangzhou.aliyuncs.com/home/assets/english/15.jpg",
        "https://dr-wangwang-3d-print.oss-cn-hangzhou.aliyuncs.com/home/assets/english/16.jpg",
        "https://dr-wangwang-3d-print.oss-cn-hangzhou.aliyuncs.com/home/assets/english/17.jpg",
        "https://dr-wangwang-3d-print.oss-cn-hangzhou.aliyuncs.com/home/assets/english/18.jpg",
        "https://dr-wangwang-3d-print.oss-cn-hangzhou.aliyuncs.com/home/assets/english/19.jpg",
        "https://dr-wangwang-3d-print.oss-cn-hangzhou.aliyuncs.com/home/assets/english/20.jpg",
        "https://dr-wangwang-3d-print.oss-cn-hangzhou.aliyuncs.com/home/assets/english/21.jpg",
        "https://dr-wangwang-3d-print.oss-cn-hangzhou.aliyuncs.com/home/assets/english/22.jpg",
        "https://dr-wangwang-3d-print.oss-cn-hangzhou.aliyuncs.com/home/assets/english/23.jpg",
        "https://dr-wangwang-3d-print.oss-cn-hangzhou.aliyuncs.com/home/assets/english/24.jpg",
        "https://dr-wangwang-3d-print.oss-cn-hangzhou.aliyuncs.com/home/assets/english/25.jpg",
        "https://dr-wangwang-3d-print.oss-cn-hangzhou.aliyuncs.com/home/assets/english/26.jpg",
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

.container {
  padding: 5px;
}
.header{
  padding: 0;
  margin: 0;
}
</style>
