<template>
  <div>
    <HelloWorld v-if="switchLanguage === 'chinese'" msg="Welcome to Your Vue.js App"/>
    <english v-if="switchLanguage === 'english'"></english>
    <div class="button" >
      <div><el-link @click="language(1)"><div class="en">English</div></el-link> | <el-link @click="language(2)"><div class="en">Chinese</div></el-link></div>
      <!--      <el-select v-model="switchLanguage">-->
      <!--        <el-option v-for="(item,index) in languages" :key="index" :label="item" :value="item"></el-option>-->
      <!--      </el-select>-->
    </div>
<!--    <div class="button" @click="language(2)"></div>-->
    <a href="https://beian.mps.gov.cn/#/query/webSearch?code=33070202666684" rel="noreferrer" target="_blank"><img src="https://dr-wangwang-3d-print.oss-cn-hangzhou.aliyuncs.com/home/assets/beian/gh.png" width="15" height="15"/>浙公网安备33070202666684</a>
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'
import english from "@/components/english.vue";

export default {
  name: 'App',
  components: {
    HelloWorld,
    english
  },
  data() {
    return {
      languages: ['中文', 'english'],
      switchLanguage: 'chinese',
      title: '切换语言'
    }
  },
  methods: {
    language(type) {
      if (type === 1){
        this.switchLanguage = 'english'
      } else {
        this.switchLanguage = 'chinese'
      }
    }
  }
}
</script>

<style>
.en {
  width: 130px;
  height: 40px;
  font-size: 2.0em;
  line-height: 1.3em;
  cursor: pointer;
  background-color: #171717;
  color: #fff;
  border: none;
  border-radius: 5px;
  transition: all .4s;
}

.en:hover {
  border-radius: 5px;
  transform: translateY(-10px);
  box-shadow: 0 7px 0 -2px #f85959,
  0 15px 0 -4px #39a2db,
  0 16px 10px -3px #39a2db;
}

.en:active {
  transition: all 0.2s;
  transform: translateY(-5px);
  box-shadow: 0 2px 0 -2px #f85959,
  0 8px 0 -4px #39a2db,
  0 12px 10px -3px #39a2db;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.button {
  flex: auto;
  position: absolute;
  top: 5px;
  right: 0;
  z-index: 99;
  padding: 5px;
  cursor: pointer;
  border-radius: 25px;
  font-size: 18px;
}
</style>
